/***justappt CSS***/
/*** Author: Sourav Saha***/
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box !important;
}

html, body {
    /*height: 100%;*/
    height: auto !important;
    -moz-appearance: none;
    -webkit-appearance: none;
    font-size: 16px !important;
    overflow-x: hidden;
}
body {
    margin: 0;
    font-family: 'Inter' !important;
    font-style: normal;
    font-weight: 500;
    min-height: 100%;
    line-height: normal;
}
._overlay {
    display: none;
}
#_overlay_loader .ui.dimmer {
    z-index: 9999999 !important;
}
.positionRelative{
    position: relative;
}
.overflow_hidden {
    overflow: hidden !important;
}
.noBackRepeat{
    background-repeat: no-repeat;
}
.fr {
    float: right;
}

.fl {
    float: left;
}
.cl {
	clear:both;
	height:0;
	font-size: 1px;
	line-height: 0px;
	list-style:none;
}

.hide {
    display: none;
}

.show {
    display: block;
}

._display {
    display: block !important;
}

._display_inherit {
    display: inherit !important;
}
.overFlowY {
    overflow-y: auto;
}
.full_image {
    width: 100%;
    height: auto !important;
}

.center_placed_element {
    display: flex;
    align-items: center;
    justify-content: center;
}
button {
    background-color: initial;
    border: none;
    font-family: Poppins !important;
    cursor: pointer;
}
iframe {
    border: none;
}

.special_placed_element {
    align-items: center;
    justify-content: center;
}

.verticle_aligned_element {
    display: flex;
    align-items: center;
}

.background_transparent {
    background-color: transparent;
}

.pointer {
    cursor: pointer;
}

ul, ol {
    list-style: none;
}

a {
    text-decoration: none;
    cursor: pointer;
    color: inherit !important;
}

:focus {
    outline: none;
}

.one_half {
    float: left;
    width: 50%;
}
.full_width {
    float: left;
    width: 100%;
}
.one_third {
    float: left;
    width: 33.33%;
}
.two_third {
    float: left;
    width: 66.67%;
}
.one_fourth{
    width: 25%;
    float: left;
}
.three_fourth {
    width: 75%;
    float: left;
}
.marT_24{
    margin-top: 1.5rem;
}


.BtnFullScreenMode img {
    width: 2.625rem;
}

.btnBlueDownloadFile {
    padding: 0.875rem 1.25rem 0.875rem 3.125rem;
    background-color: #004DF6;
    background-repeat: no-repeat;
    background-position: left 1.375rem center;
    background-image: url(../images/icon/white_download_icon.svg);
    background-size: 1.125rem auto;
    border-radius: 0.625rem;
    color: #FFF;
    font-size: 0.875rem;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
}
.formFieldContainer #input-file-upload {
    display: none;
}
.formFieldContainer #label-file-upload, .formFileUploadMainContainer .filePreviewerLabel {
    background-color: #F7F9FD;
    border: 1.5px dashed #D5DAE5;
    border-radius: 0.625rem;
    display: block;
    height: 100%;
    float: left;
    padding: 1.5rem 1rem 1.125rem 1rem;
    width: 100%;
}
.formFileUploadMainContainer .heading {
    color: #191E35;
    text-align: center;
    font-size: 1.125rem;
    font-weight: 600;
    line-height: normal;
    margin-top: 1.5rem;
}
.formFileUploadMainContainer .tagLine {
    color: #979A9F;
    text-align: center;
    font-size: 0.8125rem;
    font-weight: 500;
    line-height: normal;
    margin-top: 0.5rem;
    padding: 0 6rem 0 6rem;
}





.highcharts-yaxis {
    display: none !important;
}
.highcharts-container {
    position: relative;
    right: 1.5rem;
}

.tableRowSelectedHighlight {
    border-left: 4px solid #004DF6;
}

.tableRowNotSelected {
    border-left: 4px solid transparent;
}
