/***justappt CSS***/
/*** Author: Sourav Saha***/
.leftMenuContainer {
    float: left;
    width: 224px;
    border-right: 1px solid #E5E9F1;
    position: fixed;
    left: 0;
    height: 100%;
    background-color: #F7F9FD;
}

    .leftMenuContainer .topSection {
        padding: 28px 16px 16px 16px;
        overflow-x: hidden;
    }

    .leftMenuContainer .logoContainer {
    }

        .leftMenuContainer .logoContainer .logoImage {
            width: 180px;
        }

    .leftMenuContainer .menuControlAction {
        padding: 10px 13px;
        background-color: #EBEFF6;
        width: 100%;
        border-radius: 12px;
        margin-top: 16px;
    }

        .leftMenuContainer .menuControlAction .displayText {
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            color: #8D95A0;
            margin-left: 16px;
        }

    .leftMenuContainer .menuContainer li.itemActive {
        padding-top: 8px;
        padding-bottom: 8px;
        background-color: #FFF;
        border-bottom: 1px solid #EBEFF6;
        border-top: 1px solid #EBEFF6;
    }

    .leftMenuContainer .menuContainer li .displayTitle {
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        color: #686A6F;
        cursor: pointer;
        position: relative;
        width: calc(100% - 18px);
        padding-left: 10px;
    }

    .leftMenuContainer .menuContainer li.itemActive .displayTitle {
        color: #004DF6;
        font-weight: 600;
    }

    .leftMenuContainer .menuContainer li .acoordionIcon {
        position: absolute;
        top: 4px;
        right: 0px;
    }

    .leftMenuContainer .menuContainer li.itemPageActive {
        padding-top: 0;
        padding-bottom: 0;
        background-color: #FFF;
        border-bottom: 1px solid #EBEFF6;
        border-top: 1px solid #EBEFF6;
    }

        .leftMenuContainer .menuContainer li.itemPageActive .displayTitle {
            color: #004DF6;
            font-weight: 600;
        }

    .leftMenuContainer .leftMenuItemInner {
        padding: 12px 20px;
    }

.leftMenuItemInner .menuIcon {
    width: 18px;
    float: left;
}

.subMenuContainer .leftSubMenuItemInner {
    padding-left: 48px;
    padding-right: 10px;
}

.subMenuContainer .leftSubMenuItem {
    margin: 4px 0;
}

    .subMenuContainer .leftSubMenuItem:first-child {
        margin-top: 0;
    }

    .subMenuContainer .leftSubMenuItem:last-child {
        margin-bottom: 8px;
    }

.leftSubMenuItemInner .displaySubTitle {
    float: left;
    width: calc(100% - 6px);
    padding-left: 10px;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

    .leftSubMenuItemInner .displaySubTitle.displaySubTitleSelected {
        color: #191E35;
        font-weight: 600;
    }

.leftMenuContainer.collaps {
    width: 88px;
}

    .leftMenuContainer.collaps .menuControlAction .displayText {
        display: none;
    }

    .leftMenuContainer.collaps .menuContainer li .displayTitle {
        display: none;
    }

    .leftMenuContainer.collaps .leftMenuItemInner {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .leftMenuContainer.collaps .leftSubMenuItemInner .displaySubTitle {
        display: none;
    }

    .leftMenuContainer.collaps .subMenuContainer .leftSubMenuItemInner {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 10px;
    }

.leftMenuContainer.collaps {
    /*width: 230px*/
}

.leftMenuContainer .menuSection {
    overflow-y: auto;
}
