/***justappt CSS***/
/*** Author: Sourav Saha***/
@media screen and (max-width:1600px) {
    html {
        font-size: 1rem !important;
    }
}
@media screen and (max-width:1550px) {
    html {
        font-size: 0.95rem !important;
    }
}
@media screen and (max-width:1500px) {
    html {
        font-size: 0.9rem !important;
    }
}

@media screen and (max-width:1400px) {
    html {
        font-size: 0.85rem !important;
    }
}