/***justappt CSS***/
/*** Author: Sourav Saha***/
.dialog {
    display: none;
    z-index: 9999;
    width: auto;
    height: auto;
    background-color: #FFFFFF;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 1.875rem;
}

.dialog_main_section {
    position: relative;
    min-height: 10rem;
    padding: 3rem 0;
}
.dialog img._close_popup {
    cursor: pointer;
    float: right;
    margin-right: 1rem;
    margin-top: 1rem;
}

.popup_1400 {
    width: 87.5rem;
}
.popup_1600 {
    width: 100rem;
}
