/***justappt CSS***/
/*** Author: Sourav Saha***/
.inputField {
    background-color: #fff;
    border: 2px solid #d9dadf;
    border-radius: 5px;
    color: #1e1e1e;
    font-family: Poppins;
    font-size: .9375rem;
    font-weight: 600;
    height: 3.125rem;
    line-height: 1.125rem;
    padding-left: 1rem;
    padding-right: 1rem;
    width: 100%;
}

a {
    text-decoration: none !important;
    color: inherit !important;
}

.formFieldContainer {
    padding: 0.5625rem 0.5rem;
}

.errorPlaceholder::-webkit-input-placeholder {
    color: #f0988e !important;
}

.errorFieldBordeer {
    border-color: #f0988e !important;
}

.textField.textFieldContainer {
    padding: 1rem;
}

    .textField.textFieldContainer.disabled {
        background-color: #F4F8FF !important;
        border-color: #E4EBF5;
        color: #67686F;
    }

.textField, .react-tel-input .form-control.textField {
    font-family: 'Poppins';
    background-color: #FFFFFF;
    border: 2px solid #D9DADF;
    border-radius: 0.625rem;
    font-weight: 600;
    font-size: 0.9375rem;
    line-height: 1.125rem;
    color: #1E1E1E;
    height: 3.125rem;
    padding-left: 1rem;
    padding-right: 1rem;
    width: 100%;
}

.formFieldContainer.unitBoxContainer {
    position: relative;
}

.unitBoxContainer .unitBox {
    width: 3.75rem;
    height: 1.75rem;
    background-color: #F2F4F8;
    border-radius: 3.125rem;
    position: absolute;
    right: 1.25rem;
    bottom: 1.25rem;
    font-size: 0.75rem;
    line-height: 0.9375rem;
    color: #5B5D63;
    text-transform: uppercase;
}

.textField::placeholder {
    font-family: 'Poppins';
    /*font-style: italic;*/
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: #C7CDD2;
}

.textField.iconPositioned {
    background-repeat: no-repeat;
    background-position: left 0.75rem center;
    padding-left: 2.625rem;
    background-size: 1.125rem auto;
}
.textField.dateIcon {
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 1.125rem auto;
    background-image: url(../images/icon/date_input_icon.svg);
    padding-right: 2.5rem;
}
textarea.textField {
    height: 7.625rem;
    resize: none;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

select.textField {
    padding-left: 1rem !important;
}
select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background-repeat: no-repeat;
    background-position: right 0.625rem center;
    background-image: url(../images/icon/dropdown_arrow_icon.svg);
}
    /*select option:first-child {
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.125rem;
        color: #C7CDD2;
    }*/
textarea:disabled, .textField:disabled {
    background-color: #F4F8FF !important;
    border-color: #E4EBF5;
}

.formFieldContainer label.label {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 0.3125rem;
}

.applicationLargeBtn {
    width: 100%;
    padding: 1.125rem;
    background-color: #114FE2;
    border-radius: 5px;
    font-weight: 600;
    font-size: 0.9375rem;
    line-height: 1.125rem;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #FFFFFF;
}

.passwordFormFieldContainer {
    position: relative;
}

    .passwordFormFieldContainer .btnPasswordShowHide {
        position: absolute;
        width: 1.75rem;
        height: 1.75rem;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url(../images/icon/password_off_icon.svg);
        background-size: 1.5rem auto;
        bottom: 1.25rem;
        right: 0.75rem;
    }

        .passwordFormFieldContainer .btnPasswordShowHide.passwordShow {
            background-image: url(../images/icon/password_on_icon.svg);
        }
    .passwordFormFieldContainer .btnPasswordShowHide {
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACYAAAAfCAYAAACLSL/LAAAACXBIWXMAABCcAAAQnAEmzTo0AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAARFSURBVHgB3ZdbTxNBFMfPbhsaxUuNSqIPuvpgNMZ4ffRSH0TwRcZK4mP7CYRPYPsJKp+gvPkAZPFJxRgw+mCCEQzeRdmiFYMmNtEWsHTHc7bdYbqd0nJPPMmms7OzM7/+58x/ZgHWIDjnEa6OJF4GbFTg4BN88Vh/QFmtbHaGP34yzK1UWgUXWawfP6xi4GBB/Lnl3qcm0/Dz5y/noti/b6/7aFTTtG5YKzDTvGfkbV/Ip/PjAFpwevpXsKlph0HPcrlZePvus9Nu8+ZNMhQFq9X3ksGSphkMwpabNvCIDWD4dKrVnMERSrR7++6TKJ85dVTuohvVsmC1wBaAoAOhgt7nRw4fFGVSKzX5zSmTUrt27ZCbxqGOqAusp+deSAdfEoEMzyOLc/vu7t07Mwggcuv5i1dK4HR6OvNk+AWsCphpPkwgUIdcxwGGOBTi7ax1yLnn3HSfTaJScrLTFFOQimOvPwQbwDeBfcYYuxRfFhhN3XZoNBEqJCFZNthRF6gE1YY/be79GynhZbU+T3zN5HIzTgpgn7E+82EwzC51VhtfV1XewdW2DRpHUJnQAoDdpUPupAxVioRbILVwcKcsq4VhjX8cP4mLpH/hNd7Raw6MkAAqBk0FhXIPYtEQlTaPh8OXY962JZNMUjmfn4dHg88cMAJqaT4rN426vtVjPojpoIl8xD8/+huyF6OMZeQXyhQj+gooKERVUKUQA4x/Sgm15CnEsGQzbWeXYziVIr9QmROUMt6Oy8C2wZakDIX51Blmrd2gCFTrltu2hplGve964ShlMOcSSrC+voEINhFJTNPXzlpugxrKwB+xUmuY6ZCqD4LjHLqkXjvIlirAbL1gyS9qunYBqgep5STtcs2U0kbT4KpUlfH7waoAK642WyzforwDSW+HJbUi7v3LsffimSe3qm49BLUVGstymcM85n+rVQFGEcapk+eeABRwIuHJHqampp2y1x6gilouFCW9qMS0uc6u9MvtNNXLqiWdhwK70dZi4O2gW39/4Kmwh/NnT8tgcVQr5u13KVakNFjVki51KNRbzExVUL29D9oawD9SDxRF1S2J4FA5cJXDwQ35ebWtBzxTWFTJnyiueO5Wo5kWOsNhtRVRaFAjekw6WejJluZzhqsK2YPrW6fRHiTfIrUOOO/RicSv46rTI1B2TOIW/lnGWPPoYuPWBKOYnZ1NBAIBx7fIHmjryefzFVvP8PDY0Jf0FKqhh0BxZqP91qfN4MmifPtRRc1jT8kehPGSWgRFcfzYIdGOcu5L+nuo+F95eR94TPLh1sauLdjBisGgaA8GFbxmumdPk2jk5pwMw4E/9kPudj0KLQnMa6by1iMn/Nzc3/4/uT9dxQ59CJG1lgNTNxhIZkpq/VCcTDGsQKChs53VP00rClTrhOorNYUfr9lsTq6KwXoHTSVe3bx6TMBGBgKE8BpZ6mf+fxn/ABS8voPqKNf0AAAAAElFTkSuQmCC);
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: 1.5rem auto;
        bottom: 1.25rem;
        height: 1.75rem;
        position: absolute;
        right: 0.75rem;
        width: 1.75rem;
    }


.radioBtnSpecial input[type=checkbox], .radioBtnSpecial input[type=radio] {
    opacity: 0;
    position: fixed;
    width: 0;
}
    .radioBtnSpecial input[type=checkbox] + label.systemCustomeCheckBox {
        background-color: initial;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAsCAYAAAAehFoBAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAGaSURBVFhH7ZnJboMwFEVvHDBhKNCkrfL/n5cqhDCUMLY8A4lR1aUDlXw2xg8vjuwnL3w33z2QuJUVsrToxxpd143V58JNA5yb8H0HhrEdqwN34aZpEUWpEF4TYeAJ8QkhTLKnz1hIrxFZWgif+53N80IUiCBw4Tq7X8fxLOiU40uGqm7GCvB28OH0Tox+yrKHvY/AdxeTJXYWx/G4h+vuxgoQX3MxsjwvxQdBC+RFS/MavoAxJr6pXW+3Cqyua1EgvBXJEoxtYNt8nEG0CGu7x6223S7XBn9hSE50zQ77/Y/QwqrRwqrRwqrRwqrRwqrRwqrRwqrRwqrRwqrRwqrRwqrRwqrRwqrRwqphFIBMFMXjrXgtUDg0wU0TzLLMcQqkWYFuHiotSlFUKKWQiHMDzHPt2Sv36XRB0y4fzpT9zp6jZJwNj+0UY4hQJklzxPGQIUw4tgVTapdnQSdcV80sfiPRj/fwIUzF+JohSb7EgjUhyxJ3YYJCj+iSriavozYIQ+/essRMeKLqj4SavV0ouqVcw3GsmegA8AMINLOApPOOEgAAAABJRU5ErkJggg==);
        background-position: left 0 top 0;
        background-repeat: no-repeat;
        background-size: 1.875rem auto;
        border: none;
        border-radius: 0;
        float: left;
        padding: 0 0 0 2.5rem;
        cursor: pointer;
        color: #595E63;
        font-size: 0.875rem;
        line-height: 1.875rem;
        height: 1.875rem;
    }
    .radioBtnSpecial input[type=checkbox]:checked + label.systemCustomeCheckBox {
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAsCAYAAAAehFoBAAAACXBIWXMAABCcAAAQnAEmzTo0AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAI1SURBVHgB7Zkxb9NAFMffvcSEkqi1JWBqq/YDVAp7h3ZmYWZhZ2HtRrIxMnWlEzND9wrxBfwNqIDdphICWtXX93d7leXa9TnNObaUn2T77LskPz2d38V+inKMfkZ7PVLvpLknm0+LIdRKhXyRTOPt4DTboUzD/x5t6b76RNeirUGTmpxtrE3NeSqcynrqRHq3qIVkpRm7xOP3bZUFivTE/xG9Sts3c/aE2o6i09/r/jaz4jfUBWQG+BJcVlqPqSMkCY1ZQr2o1DULPlPHWAq7Zilsw2af6fjZMD3WpXHhnUc9On46pN1Bn749H9GO16v1+UaFjayJ7BqrNNIvVzzr72hM2MhCMgvO60S5EeEyWfDh7L9s/8gW58K7g/nJAqfCr4eezNHR3GRBLeGiHy4DsofBk8K+WWWBtTDubKShg9VB5VhXssBKOE30N+noYPXxvdIuZUGlcFbWUCaNay5lQaUwVqSiJTQvjTauFTEvWdCvGvD5z3l6PAxW7vRlBZuQBZXCwFY6z7xlgXWWgPTb6K/tcCeyoFYetpV2JQtqr3RV0i5lwUxLc5m0a1kw83+JvHQTssAqS5RhssdmjxuRBQ8SBka6KZZPza5ZCruG5b1rSB1BJxSyTvRX6gjyMiBkTuhI2jG1HCmDHaEExrKLldJTajNS30DNDs30povXg49SqWmntMiqC71vCoy3WSLeCCYivY8B1BIwDUT2RbYaWviiwf8VjZPLxZVuGUG7pC+Yrvm+K7rc6AMipqMhAAAAAElFTkSuQmCC);
    }
.appInputFile{
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}
.spclAppSearchField {
    width: 18.75rem;
    height: 2.625rem;
    background-color: #FFF;
    border: 1px solid #ECF0F6;
    border-radius: 2rem;
    background-position: left 1.125rem center;
    padding-left: 2.6235rem;
    background-size: 0.875rem auto;
    background-image: url(../images/icon/search_field_icon.svg);
    font-size: 0.875rem;
    font-weight: 400;
    line-height: normal;
}
    .spclAppSearchField::placeholder {
        font-style: italic;
    }
.appSelectNoBorder {
    border: none;
    color: #191E35;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;

    background-position: right 0 center;
    background-image: url(../images/icon/dropdown_arrow_icon.svg);
    background-size: 0.75rem auto;
    padding-right: 1.75rem;
}

.sysAppActBtn {
    width: auto;
    height: auto;
    border-radius: 0.625rem;
    background-repeat: no-repeat;
    background-position: left 1.5rem center;
    color: #000;
    text-align: center;
    font-size: 0.9375rem;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    background-size: 0.875rem auto;
    padding-left: 2rem;
    margin-left: 0.5rem;
    padding: 0.875rem 2rem 0.875rem 3rem;
}
    .sysAppActBtn.saveBtn {
        color: #FFF;
        background-color: #02C6D2;
        background-image: url(../images/icon/white_tick_icon.svg);
    }
    .sysAppActBtn.cancelBtn {
        color: #E5252A;
        background-color: #FFE5E6;
        background-image: url(../images/icon/red_cross_icon.svg);
    }
    .sysAppActBtn.deleteBtn {
        background-image: url(../images/icon/delete_btn_icon.svg);
    }
.specialSaveBtn {
    padding: 1rem;
    background-color: #FFF;
    border: 2px solid #004DF6;
    color: #004DF6;
    text-align: center;
    font-size: 0.9375rem;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    border-radius: 0.625rem;
}
.profileImageUploadContainer {
    height: 7.625rem;
    background-color: #F7F9FD;
    border: 1.5px dotted #D5DAE5;
    border-radius: 1rem;
    text-align: center;
}
.profileImageUploadContainer .profileImage {
    width: 2.125rem;
    height: 2.125rem;
    margin-top: 1rem;
}
    .profileImageUploadContainer .heading {
        padding: 0 1.5rem;
        color: #191E35;
        text-align: center;
        font-size: 1rem;
        font-weight: 600;
        line-height: normal;
        margin-top: 0.5rem;
    }
    .profileImageUploadContainer .action {
        padding: 0 1.5rem;
        color: #979A9F;
        font-size: 0.75rem;
        font-style: normal;
        line-height: 1.125rem; /* 150% */
    }