/***justappt CSS***/
/*** Author: Sourav Saha***/
.headerContainer {
    width: calc(100% - 224px);
    height: 7.125rem;
    position: fixed;
    left: 224px;
    top: 0;
    background-color: #F7F9FD;
    border-bottom: 1px solid #E5E9F1;
    z-index: 1;
}

    .headerContainer.collaps {
        left: 88px;
        width: calc(100% - 88px);
    }

    .headerContainer .headerTop {
        padding-bottom: 0.875rem;
    }

        .headerContainer .headerTop .headerTopLeft {
            width: 50%;
            padding: 1.25rem 1rem 0 3rem;
        }

        .headerContainer .headerTop .headerTopRight {
            width: 50%;
            padding: 1.25rem 2rem 0 1rem;
        }

    .headerContainer .spclAppSearchField {
        margin-left: 0.5rem;
    }

.headerTopRight .btnAction {
    margin: 0 0.625rem;
    height: 2rem;
}

    .headerTopRight .btnAction.btnNormAction {
    }

.headerTopRight .appSelectNoBorder {
    height: 2rem;
}

.headerTopRight .btnAction img {
    width: 1.25rem;
}

.headerTopRight .btnAction.sessionBtn {
    margin-left: 4.5rem;
}

.headerTopRight .profileInfoContainer .profileImage {
    width: 2.125rem;
    float: left;
    border-radius: 50%;
}

.headerTopRight .profileInfoContainer .profileInfo {
    width: calc(100% - 2.125rem);
    float: left;
    padding-left: 0.625rem;
    padding-right: 0.5rem;
    background-image: url(../images/icon/dropdown_arrow_icon.svg);
    background-position: right 0 center;
    background-size: 0.75rem auto;
}

.headerTopRight .profileInfoContainer .actionArrow {
    /*width: 0.75rem;
    float: left;*/
}

.headerTopRight .profileInfoContainer .profileInfo .name {
    color: #191E35;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: left;
}

.headerTopRight .profileInfoContainer .profileInfo .role {
    color: #8C9099;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: normal;
    text-align: left;
    margin-top: 0.125rem;
}

.headerContainer .headerBottom {
    padding-left: 3rem;
}

.headerPageItemContainer .headerPageItem {
    padding-right: 0.75rem;
    margin-right: 0.75rem;
}

    .headerPageItemContainer .headerPageItem a {
        color: #6C6F75;
        font-size: 0.8125rem;
        font-weight: 500;
        line-height: 1.125rem; /* 138.462% */
        margin-right: 0.875rem;
        padding-bottom: 1rem;
        float: left;
    }

    .headerPageItemContainer .headerPageItem.itemPageActive a {
        color: #004DF6;
        font-weight: 600;
        background-position: center bottom 0;
        background-image: url(../images/icon/header_page_selection_icon.svg);
        background-size: 2.5rem auto;
        background-repeat: no-repeat;
    }
